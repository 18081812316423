<template>
    <div id="crm-followups" class="page">
      <b-card no-body class="card-custom card-custom-footer-pagination">
        <template #header>
          <b-link class="float-right" @click="toggleFilters()">
            <b-badge v-if="countFilters > 0" variant="warning" class="float-right">{{ countFilters }}</b-badge>
            <BIconFilter font-scale="1.4"/>
          </b-link>
          <h6 class="mb-0">Follow-ups ({{results.total}})</h6>
        </template>
        <div v-if="showFilters" class="border-bottom" style="padding: 20px; background: #f5f5f5;">
          <b-row>
            <b-col lg="6" md="12" sm="12">
              <b-form-input @change="updateResults()" v-model="filters.query" type="text" placeholder="find..."></b-form-input>
            </b-col>
            <b-col lg="6" md="12" sm="12">
              <b-form-select @change="updateResults()" v-model="filters.channels.selected" :options="filters.channels.options"></b-form-select>
            </b-col>
          </b-row>
        </div>
        <div class="table-custom">
          <b-table v-if="!results.isLoading" responsive hover :items="results.records" :fields="results.fields" empty-text="no followups" show-empty>
            <template #cell(options)="data">
              <div class="text-right">
                <b-link v-if="user.hasPermission('crm:contact.notes.search') && data.item.totalNotes > 0" v-b-tooltip.hover title="Notes"
                  :to="{name: 'crm.contact', params: {contactId: data.item.id}, query: {notes: 1}}" class="mr-3 call-notif-badge-container">
                  <b-badge variant="danger" class="call-notif-badge rounded-circle">{{data.item.totalNotes}}</b-badge>
                  <BIconJournalText/>
                </b-link>
                <b-link :to='{name: "crm.contact", params: {contactId: data.item.id}}'><BIconInfoCircle/></b-link>
              </div>
            </template>
          </b-table>
          <div v-else style="padding: 20px;"><b-spinner label="Loading..." small></b-spinner></div>
        </div>
        <template v-if="!results.isLoading && results.pagination.totalPages > 1 " #footer>
          <PaginationRouter :pagination="results.pagination" :linkGen="paginationRouter"/>
        </template>
      </b-card>
    </div>
</template>

<script>
import moment from 'moment';
import {BIconFilter,BIconInfoCircle,BIconJournalText} from 'bootstrap-vue';
import PaginationRouter from '@/common/components/Pagination.Router';
export default {
    props: ['user'],
    components: {
      BIconFilter,BIconInfoCircle,BIconJournalText,
      PaginationRouter
    },
    data(){
        return {
          showFilters: false,
          filters: {
            query: null,
            channels: {
              selected: null,
              options: []
            },
          },
          results: {
            isLoading: false,
            fields: [
              {
                key: 'createdAt',
                label: "Datetime",
                formatter: (value) => {
                  return moment(value).format('DD/MM/YYYY')
                }
              },
              {
                key: 'fullName',
                label: "Name"
              },
              {
                key: 'phoneNumber',
                label: "Phone"
              },
              {
                key: 'email',
                label: "E-Mail"
              },
              {
                key: 'source',
                label: "Channel",
                formatter: (value) => {
                  let id = value.replace('channel_','');
                  let channel = this.channels.find(o => o.id === id);
                  return channel ? channel.name : '-';
                }
              },
              {
                key: 'options',
                label: ""
              },
            ],
            records: [],
            total: 0,
            pagination: {
              currentPageNo: 1
            }
          }
        };
    },

    methods:{
      getFiltersQuery(){
        let query = {};
        if(this.filters.query){
          query.query = this.filters.query;
        }
        if(this.filters.channels.selected){
          query.channel = this.filters.channels.selected;
        }
        return query;
      },
      toggleFilters(){
        this.showFilters = !this.showFilters;
      },

      notifyParent(){
        let data = {active: "crm.followups"};
        this.$emit('set-sidebar',data);
        this.$emit('set-navbar',data);
      },

      getFollowups(pageNo){
        this.results.isLoading = true;
        return this.$api.get('crm/followups',{
          params: Object.assign(this.getFiltersQuery(),{
            perPage: 50,
            page: pageNo || 1
          })
        }).then(({data}) => {
          this.results.records = data.records;
          this.results.total = data.total;
          this.results.pagination = data.pagination;
          this.results.isLoading = false;
        })
      },

      updateResults(){
        this.$router.push({name: "crm.followups", query: this.getFiltersQuery()})
      },
      paginationRouter(pageNum){
        let query =  this.getFiltersQuery();
        if(pageNum > 1){
            query.page = pageNum;
        }

        return this.$router.resolve({name: 'crm.followups',query: query}).href;
      },
    },

    mounted(){
        this.notifyParent();
        this.getFollowups();
        this.filters.channels.options = [{text: "- channel -",value: null}].concat(this.channels.map(item => {
          return {text: item.name,value: item.id}
        }));
    },

    watch: {
      "$route.query": function(values){
        let {page, query, channel} = values;
        this.filters.query = query;
        this.filters.channels.selected = channel || null;
        this.getFollowups(page || 1);
      }
    },

    computed: {
      channels(){
        return this.$store.getters['Callcenter/getChannels'];
      },

      countFilters(){
        let cnt = 0;
        if(this.filters.query) cnt++;
        if(this.filters.channels.selected) cnt++;
        return cnt;
      }
    }
};
</script>
